
.tab-content-wrapper{ height: 100%; display: flex; }
#work-types-list{ width: var(--work-types-list-width); right: auto; height: 100%; overflow-y: auto; border-right: 1px solid #ddd; }
#work-types-view{ overflow-y: auto; flex: 1; }

#work-types-list .item{ display: flex; flex-direction: row; padding: 5px; border: 1px solid #aaa; border-radius: 6px; margin: 2px 0px; cursor: pointer; }
#work-types-list .item:hover{ background-color: #e6f2ff; }
#work-types-list .item .icon{  display: flex; flex-direction: column; align-items: center; padding: 0px 5px; border-right: 1px solid #ddd; }
#work-types-list .item .icon img{ width: 40px; }
#work-types-list .item .top{ display: flex; }
#work-types-list .item .bottom{ display: flex; flex-direction: column; }
#work-types-list .item .name{ font-size: 18px; font-weight: bold; color: navy; }
#work-types-list .item .label{ font-weight: bold; }
#work-types-list .item .info{ width: 100%; padding: 0px 7px; }
#work-types-list .item .group-title{ color: #975600; font-weight: bold; }
#work-types-list .item .extra-info{ display: flex; }
#work-types-list .item .icon-label{ font-size: 20px; line-height: 0; }
#work-types-list .item .period, #work-types-list .item .start-point{ padding: 2px 6px; border-radius: 6px; margin-right: 10px; }
#work-types-list .item .period{ background-color: #8dd2ff; }
#work-types-list .item .start-point{ background-color: #eeff8d; }
#work-types-list .item .cluster{ display: flex; flex-direction: column; text-align: center; margin-left: 0px; border-left: 1px solid #ddd; padding: 0px 5px; }
#work-types-list .item .cluster-title{ background-color: #8dffaa; border-radius: 6px; padding: 2px; }
.work-type-id{ font-size: 25px; font-weight: bold; }