body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root{
	--nanbar-height: 35px;
	--manage-panel-width: 100px;
	--input-margin: 5px;
	--work-types-list-width: 500px;

}


#root{ position: absolute; top: 0; right: 0; bottom: 0; left: 0; }

::-webkit-scrollbar{ width: 6px; }
::-webkit-scrollbar-thumb{ background: #bbb; border-radius: 3px; }
::-webkit-scrollbar-thumb:hover{ background: #aaa; }
::-webkit-scrollbar-track-piece{ background: transparent; }

.frame{ position: absolute; top: 0; right: 0; bottom: 0; left: 0; overflow-y: auto; }
.frame.top{ bottom1: auto; }
.frame.right{ left1: auto; }
.frame.bottom{ top1: auto; }
.frame.left{ right1: auto; }